import { ReactComponent as Logo } from "../assets/logos/logo.svg";
import { ReactComponent as ThreeBars } from "../assets/icons/menu-closed.svg";
import { ReactComponent as CloseIcon } from "../assets/icons/menu-open.svg";
import LetsPlayGif from "../assets/logos/lets-play.gif";
import { Link } from "react-router-dom";
import { BackgroundVideo } from "./BackgroundVideo";

export const NavigationBar = ({ open, handleOpen }) => {
  const letsPlayHeight = matchMedia("only screen and (max-width: 750px)")
    .matches
    ? "h-8"
    : "h-16";

  return (
    <div className="w-screen bg-black fixed flex justify-between items-center p-4 z-50">
      <Link to="/" onClick={()=> {
        window.scrollTo(0, 0);
      }}>
        <Logo className={`sm:ml-4 md:ml-12 ${letsPlayHeight}`} />
      </Link>
      <nav className=" flex items-center sm:space-x-4 md:space-x-8 md:mr-12">
        <span className="uppercase text-sunny-yellow tracking-widest font-bold mr-4">
          <Link to="/focus-areas">
            <img
              src={LetsPlayGif}
              alt="Let's Play"
              className={letsPlayHeight}
            />
          </Link>
        </span>
        <span
          className="cursor-pointer w-8 h-8 flex justify-center"
          onClick={handleOpen}
        >
          {open ? <CloseIcon className="p-1" /> : <ThreeBars />}
        </span>
      </nav>
    </div>
  );
};

export const NavigationScreen = ({ isNavOpen, handleOpen }) => {
  const fontSize = matchMedia("only screen and (max-width: 750px)").matches
    ? "text-7xl"
    : "text-9xl";
  const menuPaddingTop = matchMedia("only screen and (max-width: 750px)")
    .matches
    ? "pt-32"
    : "pt-16";
  return (
    <>
      {isNavOpen && (
        <BackgroundVideo nav className="bg-black fixed top-12">
          <ul
            className={`flex flex-col items-center space-y-4 ${menuPaddingTop} font-bold ${fontSize} overflow-hidden uppercase tracking-widest relative z-40`}
            style={{ maxHeight: "calc(100vh-10rem)" }}
          >
            <Link to="/" onClick={()=> {
                handleOpen()
                window.scrollTo(0, 0);
            }}>
              <li>Home</li>
            </Link>
            <a href="/#our-work" onClick={handleOpen}>
              <li>Our Work</li>
            </a>
            <Link to="/focus-areas" onClick={handleOpen}>
              <li>Focus Areas</li>
            </Link>
            <a
              href="https://forms.gle/96v3u5jWPcnPPont5"
              target="_blank"
              rel="noreferrer"
              onClick={handleOpen}
            >
              <li>Contact Us</li>
            </a>
          </ul>
        </BackgroundVideo>
      )}
    </>
  );
};
