import { Link } from "../components/Link";
import { PageContainer } from "../components/PageContainer";

export const SubSkill = ({ name, data }) => {
  return (
    <PageContainer className="h-full overflow-hidden sm:px-12 md:px-20 pb-0">
      {matchMedia("only screen and (max-width: 750px)").matches ? (
        <div className="mt-8 flex flex-col items-center">
          <div className="text-8xl leading-less text-sunny-orange opacity-35 uppercase tracking-widest font-bold relative top-7 -z-1">
            {name}
          </div>
          {data.map((skill) => (
            <Link className="mb-12" to={`/${skill.link}`}>
              {skill.name}
            </Link>
          ))}
        </div>
      ) : (
        <div className="h-full mt-16 flex flex-nowrap items-center">
          <div
            className="leading-less tracking-widest uppercase opacity-35 mr-8"
            style={{ fontSize: "325px", maxWidth: "600px" }}
          >
            {name === "introspect" ? (
              <>
                <span className="inline-block">intro</span>
                <span className="inline-block">spect</span>
              </>
            ) : (
              name
            )}
          </div>
          <div
            className="h-full flex flex-col items-left justify-center space-y-6 relative -top-8"
            style={{ height: "75vh" }}
          >
            {data.map((skill) => (
              <Link
                className="text-7xl font-bold italic text-dirty-yellow hover:text-sunny-yellow"
                to={`/${skill.link}`}
              >
                {skill.name}
              </Link>
            ))}
          </div>
        </div>
      )}
    </PageContainer>
  );
};
