import React from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import Adrift from "../assets/video/adrift.mp4";
import AdriftDesktop from "../assets/video/adrift-desktop.mp4";
import CosmicDrive from "../assets/video/cosmic-drive.mp4";
import CosmicDriveDesktop from "../assets/video/cosmic-drive-desktop.mp4";
import IntoTheWild from "../assets/video/into-the-wild.mp4";
import IntoTheWildDesktop from "../assets/video/into-the-wild-desktop.mp4";
import SandsOfTime from "../assets/video/sands-of-time.mp4";
import SandsOfTimeDesktop from "../assets/video/sands-of-time-desktop.mp4";
import Enchanted from "../assets/video/enchanted.mp4";
import EnchantedDesktop from "../assets/video/enchanted-desktop.mp4";
import MurderMystery from "../assets/video/murder-mystery.mp4";
import MurderMysteryDesktop from "../assets/video/murder-mystery-desktop.mp4";
import ProtectorsUnite from "../assets/video/protectors-unite.mp4";
import ProtectorsUniteDesktop from "../assets/video/protectors-unite-desktop.mp4";
import HomeBackgroundVideo from "../assets/video/default-background-phone.mp4";
import HomeBackgroundVideoDesktop from "../assets/video/default-background-desktop.mp4";

export const BackgroundVideo = ({ children, nav, className }) => {
  const path = useLocation();

  const pathToVideo = () => {
    if (matchMedia("only screen and (max-width: 750px)").matches) {
      switch (path.pathname.slice(1)) {
        case "adrift":
          return Adrift;
        case "cosmic-drive":
          return CosmicDrive;
        case "into-the-wild":
          return IntoTheWild;
        case "sands-of-time":
          return SandsOfTime;
        case "enchanted":
          return Enchanted;
        case "murder-mystery":
          return MurderMystery;
        case "protectors-unite":
          return ProtectorsUnite;
        default:
          return HomeBackgroundVideo;
      }
    } else {
      switch (path.pathname.slice(1)) {
        case "adrift":
          return AdriftDesktop;
        case "cosmic-drive":
          return CosmicDriveDesktop;
        case "into-the-wild":
          return IntoTheWildDesktop;
        case "sands-of-time":
          return SandsOfTimeDesktop;
        case "enchanted":
          return EnchantedDesktop;
        case "murder-mystery":
          return MurderMysteryDesktop;
        case "protectors-unite":
          return ProtectorsUniteDesktop;
        default:
          return HomeBackgroundVideoDesktop;
      }
    }
  };

  const style = nav ? { zIndex: "39" } : { zIndex: "-10" };

  return (
    <div className={`w-full h-full ${className}`}>
      <video
        autoPlay
        loop
        muted
        playsInline
        className="fixed w-full h-full object-fill"
        style={style}
      >
        <source src={pathToVideo()} type="video/mp4" />
        video tag unsupported
      </video>
      {children}
    </div>
  );
};

BackgroundVideo.propTypes = {
  children: PropTypes.node.isRequired,
  nav: PropTypes.bool,
  className: PropTypes.string,
};
