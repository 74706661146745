import { useLayoutEffect } from "react";
import { Stats } from "../components/Stats";
import { Embed } from "../components/Embed";
import { Carousel } from "react-responsive-carousel";
import "../carousel.css";
import { Testimonial } from "../components/Testimonial";
import { Link } from "react-router-dom";
import { ReactComponent as RightArrow } from "../assets/icons/right-arrow.svg";
import { ReactComponent as LeftArrow } from "../assets/icons/left-arrow.svg";
import { PageContainer } from "../components/PageContainer";
import LetsPlayGif from "../assets/logos/lets-play.gif";
import UnboxArenaLogo from "../assets/logos/unbox-arena.gif";

export const Home = ({ data }) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <PageContainer>
      {/* Home page with moving unbox video */}
      <>
        <div
          className="flex flex-col justify-around items-center"
          style={{ minHeight: "calc(100vh - 5rem)" }}
        >
          <img
            src={UnboxArenaLogo}
            alt=""
            className="sm:min-w-80vw md:max-w-40vw md:min-w-40vw"
          />
          <h1 className="text-4xl md:text-8xl text-center italic tracking-wide pt-8">
            PLAY to LEARN
            <br />
            LEARN to PLAY
          </h1>
        </div>

        {/* Who we are */}
        <div className="flex flex-col items-center mt-16 md:mt-24 mb-24 md:mb-32">
          <div className="italic tracking-widest text-justify">
            {matchMedia("only screen and (max-width: 750px)").matches ? (
              <>
                <span
                  className="block"
                  style={{ fontSize: "4.5rem", lineHeight: "0.8" }}
                >
                  We Create
                </span>
                <span
                  className="block"
                  style={{ fontSize: "4.5rem", lineHeight: "0.8" }}
                >
                  immersive
                </span>
                <span
                  className="block"
                  style={{ fontSize: "5.125rem", lineHeight: "0.8" }}
                >
                  gamified
                </span>
                <span
                  className="block"
                  style={{ fontSize: "3.625rem", lineHeight: "1" }}
                >
                  fun learning
                </span>
                <span
                  className="block"
                  style={{ fontSize: "3.625rem", lineHeight: "0.8" }}
                >
                  experiences
                </span>
                <span
                  className="block"
                  style={{ fontSize: "2.5rem", lineHeight: "1" }}
                >
                  to help your team
                </span>
                <span
                  className="block"
                  style={{ fontSize: "2.75rem", lineHeight: "0.8" }}
                >
                  perform better
                </span>
              </>
            ) : (
              <>
                <span
                  className="block"
                  style={{ fontSize: "9.375rem", lineHeight: "0.9" }}
                >
                  We create immersive, gamified,
                </span>
                <span
                  className="block"
                  style={{ fontSize: "11.5625rem", lineHeight: "0.8" }}
                >
                  fun learning experiences
                </span>
                <span
                  className="block"
                  style={{ fontSize: "8.75rem", lineHeight: "0.9" }}
                >
                  to help your team perform better
                </span>
              </>
            )}
          </div>
        </div>
      </>

      {/* Our Work */}
      <section
        className="flex flex-col items-center justify-between w-full pt-16 md:pt-24 mb-24 md:mb-32"
        id="our-work"
        style={{height:"calc(100vh - 10rem)"}}
      >
        <Embed
          src="https://www.youtube.com/embed/7SOrKmvgJx0"
          className="text-sunny-yellow"
        />

        <div
          className={`flex flex-row flex-wrap justify-around items-center min-w-full mt-8`}
        >
          {data.statistics.map((statistic) => (
            <Stats
              label={statistic.label}
              number={statistic.number}
              key={statistic.label}
            />
          ))}
        </div>
      </section>


        <span className={`pt-8 overflow-visible`}>
          <Carousel
            width="65vw"
            showIndicators={false}
            showThumbs={false}
            showStatus={false}
            autoPlay={true}
            interval={7000}
            infiniteLoop={true}
            useKeyboardArrows={true}
            showArrows={true}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  className="absolute top-1/4 md:top-1/3 -left-6 md:-left-16 w-5 md:w-12 h-5 md:h-12 cursor-pointer"
                >
                  <LeftArrow />
                </button>
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  className="absolute top-1/4 md:top-1/3 -right-6 md:-right-16 w-5 md:w-12 h-5 md:h-12 cursor-pointer"
                >
                  <RightArrow />
                </button>
              )
            }
          >
            {data.testimonials.map((testimonial) => (
              <Testimonial
                quote={testimonial.quote}
                attribution={testimonial.attribution}
                id={testimonial.id}
                key={testimonial.id}
              />
            ))}
          </Carousel>
        </span>

      <div className="text-6xl text-yellow-500 md:w-1/2 pb-24">
        <Link to="/focus-areas">
          <img src={LetsPlayGif} alt="Let's Play" />
        </Link>
      </div>
    </PageContainer>
  );
};
