import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { Home } from "./pages/Home";
import { Skills } from "./pages/Skills";
import { SubSkill } from "./pages/SubSkill";
import data from "./data.json";
import { Game } from "./pages/Game";

const injectGA = () => {
  if (typeof window == "undefined") {
    return;
  }
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag("js", new Date());

  gtag("config", "G-ZDFNTCJKZE");
};

ReactDOM.render(
  <React.StrictMode>
    <>
      {/*Global site tag (gtag.js) - Google Analytics*/}
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-ZDFNTCJKZE"
      ></script>
      <script>{injectGA()}</script>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home data={data.homepage} />} />
          <Route path="/focus-areas" element={<Skills data={data.skills} />} />
          {data.skills.skills.map((skill) => (
            <Route
              key={skill}
              path={`/focus-areas/${skill}`}
              element={
                <SubSkill name={skill} data={data.skills.subSkills[skill]} />
              }
            />
          ))}
          {data.games.map((game) => (
            <Route
              key={game.title}
              path={`/${game.title.split(" ").join("-")}`}
              element={<Game game={game} />}
            />
          ))}
        </Routes>
      </BrowserRouter>
    </>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
