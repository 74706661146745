import PropTypes from "prop-types";
import "./embed.css";

export const Embed = ({ className, src }) => {
  const styleObject = matchMedia("only screen and (max-width: 750px)").matches
    ? { aspectRatio: "16/9", width: "fit-content" }
    : { aspectRatio: "16/9", height: "40vh" };

  return (
    <div className={`container w-auto ${className}`}>
      <div className="top-border"></div>
      <iframe
        src={src}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={styleObject}
      ></iframe>
      <div className="bottom-border"></div>
    </div>
  );
};

Embed.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
};
