import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { PageContainer } from "../components/PageContainer";
import { Embed } from "../components/Embed";
import { Heading } from "../components/game/Heading";
import { ReactComponent as CurvedLeftArrow } from "../assets/icons/curved-left-arrow.svg";
import { COLORS } from "../lib/colors.js";
import { ReactComponent as AdriftBorder } from "../assets/borders/border-adrift.svg";
import { ReactComponent as IntoTheWildBorder } from "../assets/borders/border-into-the-wild.svg";
import { ReactComponent as CosmicDriveBorder } from "../assets/borders/border-cosmic-drive.svg";
import { ReactComponent as ProtectorsUniteBorder } from "../assets/borders/borders-protectors-unite.svg";
import { ReactComponent as EnchantedBorder } from "../assets/borders/border-enchanted.svg";
import { ReactComponent as MurderMysteryBorder } from "../assets/borders/border-murder-mystery.svg";
import { ReactComponent as SandsOfTimeBorder } from "../assets/borders/border-sands-of-time.svg";
import AdriftMascot from "../assets/video/mascot-adrift.gif";
import IntoTheWildMascot from "../assets/video/mascot-into-the-wild.gif";
import MurderMysteryMascot from "../assets/video/mascot-murder-mystery.gif";
import CosmicDriveMascot from "../assets/video/mascot-cosmic-drive.gif";
import EnchantedMascot from "../assets/video/mascot-enchanted.gif";
import ProtectorsUniteMascot from "../assets/video/mascot-protectors-unite.gif";
import SandsOfTimeMascot from "../assets/video/mascot-sand-of-time.gif";

export const Game = ({ game }) => {
  const id = game.title.split(" ").join("-");
  const history = useNavigate();
  const headerWidth =
    id === "into-the-wild" || id === "cosmic-drive" || id === "sands-of-time"
      ? "w-1/2"
      : id === "adrift"
      ? "w-4/5"
      : id === "protectors-unite"
      ? "w-2/5"
      : "w-3/5";
  return (
    <PageContainer
      className={`font-${game.metadata.font} text-${
        game.metadata.color
      } sm:px-8 ${
        matchMedia("only screen and (max-width: 750px)").matches || "px-20"
      }`}
    >
      <>
        {/*Heading page*/}
        <div
          className={`md:flex md:flex-col md:items-center mt-32 md:mt-0 md:mb-20 md:${headerWidth}`}
          style={{ minHeight: "calc(100vh - 10rem)" }}
        >
          <Heading id={id} />
          <div className="font-geometric text-xl md:text-4xl text-white text-right">
            {game.subtitle}
          </div>
        </div>
        <div className="flex flex-col items-center mb-20 md:mb-0">
          {/* TODO: Draw border instead of adding it */}
          <div className="md:flex md:flex-col md:items-center md:w-full mb-32 sm:space-y-24 md:space-y-12">
            <Item
              color={game.metadata.color}
              description={game.skillsUsed[0].content}
              skill={game.skillsUsed[0].heading}
              textAlign="left"
            />
            <span
              className="md:grid md:mt-0"
              style={{ gridTemplateColumns: "repeat(3, 1fr)" }}
            >
              {matchMedia("only screen and (max-width: 750px)")
                .matches ? null : (
                <Mascot
                  id={id}
                  className="h-80"
                  style={{ gridArea: "1/1/2/2" }}
                />
              )}
              <Item
                className="mt-12 md:mt-0"
                color={game.metadata.color}
                description={game.skillsUsed[1].content}
                skill={game.skillsUsed[1].heading}
                textAlign="right"
                style={{ gridArea: "1/2/2/4" }}
              />
            </span>
          </div>

          <Embed src={game.video} />
          <Item
            color={game.metadata.color}
            skill={game.description.heading}
            description={game.description.content}
            textAlign="center"
            className="mt-12 pb-20"
          />
        </div>

        {matchMedia("only screen and (max-width: 750px)").matches ? null : (
          <button
            onClick={() => history(-1)}
            className={`sm:none md:flex items-center justify-start w-full text-${game.metadata.color} uppercase text-sm mb-6 ml-40 fixed bottom-0`}
          >
            <CurvedLeftArrow
              class="h-4"
              style={{
                fill: COLORS[game.metadata.color],
                stroke: COLORS[game.metadata.color],
              }}
            />{" "}
            Back
          </button>
        )}
      </>
    </PageContainer>
  );
};

Game.propTypes = {
  game: PropTypes.object.isRequired,
};

const Item = ({ className, color, description, skill, textAlign, style }) => {
  const alignSelf =
    textAlign === "center"
      ? "self-center"
      : textAlign === "left"
      ? "md:ml-12 self-start"
      : "md:mr-20 self-end";
  return (
    <div
      className={`md:flex md:flex-col ${alignSelf} ${className}`}
      style={style}
    >
      <h2
        className={`text-4xl md:text-7xl text-${color} text-${textAlign} ${
          textAlign === "center"
            ? null
            : textAlign === "left"
            ? "md:pr-60"
            : null
        }`}
      >
        {skill}
      </h2>
      <div
        className={`font-geometric text-xl md:text-3xl text-white text-${textAlign} ${
          textAlign === "center"
            ? "px-12 md:px-0 md:w-3/5 self-center"
            : textAlign === "left"
            ? "pr-12 md:pr-0 md:w-2/5"
            : "pl-12 md:pl-60 w-full self-end"
        }`}
      >
        {description}
      </div>
    </div>
  );
};

Item.propTypes = {
  skill: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  textAlign: PropTypes.oneOf(["left", "right", "center"]),
  className: PropTypes.string,
};

const Border = ({ id, className }) => {
  switch (id) {
    case "adrift":
      return <AdriftBorder className={className} />;
    case "into-the-wild":
      return <IntoTheWildBorder />;
    case "cosmic-drive":
      return <CosmicDriveBorder />;
    case "sands-of-time":
      return (
        <SandsOfTimeBorder
          className={`relative ${className}`}
          style={{ zIndex: "1" }}
        />
      );
    case "enchanted":
      return <EnchantedBorder />;
    case "murder-mystery":
      return <MurderMysteryBorder />;
    case "protectors-unite":
      return <ProtectorsUniteBorder />;
    default:
      console.error("the id was incorrect");
      return null;
  }
};

Border.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
};

const Mascot = ({ id, className, style }) => {
  const pathToVideo = (id) => {
    switch (id) {
      case "adrift":
        return AdriftMascot;
      case "cosmic-drive":
        return CosmicDriveMascot;
      case "into-the-wild":
        return IntoTheWildMascot;
      case "sands-of-time":
        return SandsOfTimeMascot;
      case "enchanted":
        return EnchantedMascot;
      case "murder-mystery":
        return MurderMysteryMascot;
      case "protectors-unite":
        return ProtectorsUniteMascot;
      default:
        console.error("id not available");
        return null;
    }
  };

  return (
    <img
      src={pathToVideo(id)}
      alt=""
      className={` ${className}`}
      style={{ ...style, objectFit: "cover" }}
    />
  );
};

Mascot.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.string,
};
