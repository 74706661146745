import PropTypes, { node, string } from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { ReactComponent as RightChevron } from "../assets/icons/right-chevron.svg";

const getTextType = (type) => {
  switch (type) {
    case "big":
      return {
        class:
          "text-7xl leading-less uppercase tracking-widest font-bold border-solid border-b-2 border-gray-400",
        chevron: (
          <span>
            <RightChevron className="inline-block mb-10 md:mb-16" />
          </span>
        ),
      };
    case "nav":
      return {
        class: "text-base uppercase font-bold text-sunny-yellow",
        chevron: null,
      };
    default:
      return {
        class:
          "text-4xl capitalize tracking-wider font-bold italic border-solid border-b-2 border-gray-400",
        chevron: <RightChevron className="ml-2" />,
      };
  }
};

export const Link = ({ className, children, type, to }) => {
  const textType = getTextType(type);
  return (
    <div className="flex items-baseline">
      <span
        className={`${textType.class} ${className} inline-block text-center`}
      >
        <RouterLink to={to}>{children}</RouterLink>
      </span>
      {textType.chevron}
    </div>
  );
};

Link.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([string, node]).isRequired,
  type: PropTypes.oneOf(["big", "nav"]),
  to: PropTypes.string.isRequired,
};
