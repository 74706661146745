import PropTypes from "prop-types";
import { ReactComponent as OpenQuotes } from "../assets/icons/open-quote.svg";
import { ReactComponent as CloseQuotes } from "../assets/icons/close-quote.svg";

export const Testimonial = ({ quote, attribution, id }) => {
  const testimonialID = `testimonial-${id}`;
  return (
    <div className="flex flex-col items-center ">
      <div
        className="w-4/5 font-geometric text-sm md:text-4xl text-gray-300 text-center whitespace-pre-wrap my-8"
        id={testimonialID}
      >
        <OpenQuotes className="inline-block h-6 -ml-8 -mt-4" />
        {quote}
        <CloseQuotes className="inline-block h-6 ml-4" />
      </div>
      <div className="w-3/5 font-geometric text-sm md:text-3xl text-gray-400 text-center">
        {attribution}
      </div>
    </div>
  );
};

Testimonial.propTypes = {
  quote: PropTypes.string.isRequired,
  attribution: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};
