export const COLORS = {
  "sunny-orange": "#F18C00",
  "dirty-yellow": "#7E6500",
  "sunny-yellow": "#FECC00",
  "bright-teal": "#00FFD0",
  "leaf-green": "#98B76C",
  "sky-blue": "#77C4FF",
  "light-orange": "#FF983E",
  "lighter-orange": "#FFBA6B",
  "metal-gray": "#B4B5B5",
};
