import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { BackgroundVideo } from "./BackgroundVideo";
import { NavigationBar, NavigationScreen } from "./Navigation";
import useToggle from "../lib/useToggle";
import useImperativeDisableScroll from "../lib/useImperativeDisableScroll";

export const PageContainer = ({ className, children, noNavigation }) => {
  const [isNavOpen, toggleIsNavOpen] = useToggle(false);
  useImperativeDisableScroll({ element: document.body, disabled: isNavOpen });
  const isRegularPage = [
    "focus-areas",
    "think",
    "do",
    "introspect",
    "contact-us",
  ].includes(useLocation().pathname.slice(1));

  return (
    <BackgroundVideo>
      <div className="font-morganite text-white h-full">
        {noNavigation || (
          <NavigationBar open={isNavOpen} handleOpen={toggleIsNavOpen} />
        )}
        {!isNavOpen && (
          <div
            className={`flex flex-col items-center h-full ${
              isRegularPage ? "p-20" : "p-8 md:p-20"
            } ${className}`}
          >
            {children}
          </div>
        )}
        <NavigationScreen isNavOpen={isNavOpen} handleOpen={toggleIsNavOpen} />
      </div>
    </BackgroundVideo>
  );
};

PageContainer.propTypes = {
  children: PropTypes.array.isRequired,
  className: PropTypes.string,
  noNavigation: PropTypes.bool,
};
