import PropTypes from "prop-types";

export const Stats = ({ number, label }) => {
  // We are removing all styles that start with `text` from the tailwind compiler to allow our string interpolation to happen.
  // See tailwind.config.js line 5
  // This is why we cannot use responsive media modifiers on them, and so we are resorting to calculating the font size needed beforehand.
  const fontSizeNumber = matchMedia("only screen and (max-width: 750px)")
    .matches
    ? "text-7xl"
    : "text-8.75xl";
  const fontSizeLabel = matchMedia("only screen and (max-width: 750px)").matches
    ? "text-2xl"
    : "text-6xl";

  return (
    <div className="flex flex-col items-center w-28 md:w-auto h-24 md:h-auto mt-8 md:mt-0">
      <div
        className={`text-sunny-yellow ${fontSizeNumber} italic tracking-wider`}
      >
        {number}
      </div>
      <div
        className={`${fontSizeLabel} capitalize font-bold italic tracking-widest whitespace-nowrap -mt-2 md:-mt-12`}
      >
        {label}
      </div>
    </div>
  );
};

Stats.propTypes = {
  number: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};
