import PropTypes from "prop-types";
import { ReactComponent as AdriftGameElement } from "../../assets/icons/adrift.svg";
import { ReactComponent as CosmicDriveGameElement } from "../../assets/icons/cosmic-drive.svg";
import { ReactComponent as EnchantedGameElement } from "../../assets/icons/enchanted.svg";
import { ReactComponent as IntoTheWildGameElement } from "../../assets/icons/into-the-wild.svg";
import { ReactComponent as MurderMysteryGameElement } from "../../assets/icons/murder-mystery.svg";
import { ReactComponent as ProtectorsUniteGameElement } from "../../assets/icons/protectors-unite.svg";
import { ReactComponent as SandsOfTimeGameElement } from "../../assets/icons/sands-of-time.svg";

export const Heading = ({ id, heading, className }) => {
  if (matchMedia("only screen and (max-width: 750px)").matches) {
    switch (id) {
      case "adrift":
        return (
          <h1 className="leading-none -mb-2" style={{ fontSize: "6.25rem" }}>
            <AdriftGameElement
              className="h-6 relative top-8 left-4"
              style={{ transform: "rotate(-20deg)" }}
            />
            Adrift
          </h1>
        );
      case "cosmic-drive":
        return (
          <h1 className="flex flex-col text-7xl">
            <CosmicDriveGameElement
              className="h-12 relative -left-16"
              style={{ transform: "rotate(30deg)", top: "1.5rem" }}
            />
            <span className="self-start">cosmic</span>
            <span className="self-end">drive</span>
          </h1>
        );
      case "into-the-wild":
        return (
          <h1 className="w-full flex flex-col -mt-8">
            <span className="text-9xl self-start">Into</span>
            <span className="text-7xl self-center -ml-6 -mt-6 -mb-8">the</span>
            <span className="relative flex flex-row top-12 left-8">
              <IntoTheWildGameElement
                className="w-12 h-12"
                style={{ transform: "rotate(-30deg)" }}
              />
              <IntoTheWildGameElement
                className="w-6 ml-4 self-end"
                style={{ transform: "rotate(-30deg)" }}
              />
            </span>
            <span className="text-9xl self-end -mt-12">wild</span>
          </h1>
        );
      case "sands-of-time":
        return (
          <h1 className="w-full flex flex-col">
            <span className="block text-8.5xl leading-none self-start">
              Sands
            </span>
            <span className="self-end flex justify-end">
              <SandsOfTimeGameElement
                className="h-24 mr-2"
                style={{ transform: "rotate(-15deg)" }}
              />
              <span className="text-7.5xl leading-none pr-2 -mt-4">of</span>
              <span className="text-8.5xl leading-none">Time</span>
            </span>
          </h1>
        );
      case "enchanted":
        return (
          <h1>
            <EnchantedGameElement
              className="h-16 relative left-16 relative top-2"
              style={{ transform: "rotate(15deg)" }}
            />
            <span className="text-6.5xl relative">Enchanted</span>
          </h1>
        );
      case "murder-mystery":
        return (
          <h1 className="w-full text-6xl flex flex-col">
            <span className="self-start flex flex-row relative -left-12">
              <MurderMysteryGameElement
                className="h-24 relative left-12 -top-6"
                style={{ transform: "rotate(-15deg)" }}
              />
              <span>Murder</span>
            </span>
            <span className="self-end -mt-8">Mystery</span>
          </h1>
        );
      case "protectors-unite":
        return (
          <h1 className="w-full flex flex-col">
            <ProtectorsUniteGameElement className="h-32 relative top-28 -left-20" />
            <span className="relative self-end" style={{ fontSize: "40px" }}>
              protectors
            </span>
            <span className="text-7.5xl self-end -mt-10 relative">unite</span>
          </h1>
        );
      default:
        console.error('Game heading "', id, "\" didn't match any known games");
    }
  } else {
    switch (id) {
      case "adrift":
        return (
          <h1
            className="self-end leading-none -mb-2 md:text-right"
            style={{ fontSize: "16rem" }}
          >
            <AdriftGameElement
              className="h-20 relative top-28 left-2"
              style={{ transform: "rotate(-15deg)" }}
            />
            Adrift
          </h1>
        );
      case "cosmic-drive":
        return (
          <h1 className="w-full flex flex-col" style={{ fontSize: "9rem" }}>
            <CosmicDriveGameElement
              className="w-12 relative left-48"
              style={{ transform: "rotate(30deg)", top: "5.5rem" }}
            />
            <span className="self-start">cosmic</span>
            <span className="self-end -mt-20">drive</span>
          </h1>
        );
      case "into-the-wild":
        return (
          <h1 className="self-end w-4/5 flex flex-col">
            <span className="self-start" style={{ fontSize: "11rem" }}>
              Into
            </span>
            <span className="text-8xl self-center -ml-20 -mt-20 -mb-24">
              the
            </span>
            <span className="relative flex flex-row top-20 left-4">
              <IntoTheWildGameElement
                className="w-20"
                style={{ transform: "rotate(-30deg)" }}
              />
              <IntoTheWildGameElement
                className="w-12 ml-8 self-end"
                style={{ transform: "rotate(-30deg)" }}
              />
            </span>
            <span
              className="self-end -mt-24 -mb-12"
              style={{ fontSize: "11rem" }}
            >
              wild
            </span>
          </h1>
        );
      case "sands-of-time":
        return (
          <h1 className="self-end w-4/5 flex flex-col mt-24">
            <span
              className="block leading-none self-start ml-10"
              style={{ fontSize: "10rem" }}
            >
              Sands
            </span>
            <span className="self-end flex justify-end">
              <SandsOfTimeGameElement
                className="h-32"
                style={{ transform: "rotate(-15deg)" }}
              />
              <span className="text-8.5xl leading-none pr-4 -mt-8">of</span>
              <span className="leading-none" style={{ fontSize: "10rem" }}>
                Time
              </span>
            </span>
          </h1>
        );
      case "enchanted":
        return (
          <h1 className="ml-8 mt-24 mb-16">
            <EnchantedGameElement
              className="h-24 relative left-32 -top-6"
              style={{ transform: "rotate(15deg)" }}
            />
            <span className="text-9xl relative">Enchanted</span>
          </h1>
        );
      case "murder-mystery":
        return (
          <h1 className="w-full text-9xl flex flex-col mt-24 mb-6">
            <span className="self-start flex flex-row relative right-40">
              <MurderMysteryGameElement className="h-52 relative left-20 -top-12" />
              <span>Murder</span>
            </span>
            <span className="self-end -mt-20">Mystery</span>
          </h1>
        );
      case "protectors-unite":
        return (
          <h1 className="w-full flex flex-col -mt-24">
            <ProtectorsUniteGameElement className="h-60 relative top-52 -left-48" />
            <span className="text-7xl -ml-8 relative">protectors</span>
            <span className="text-9xl self-end -mt-4 mb-4 relative">unite</span>
          </h1>
        );
      default:
        console.error('Game heading "', id, "\" didn't match any known games");
    }
  }
};

Heading.propTypes = {
  id: PropTypes.string.isRequired,
  heading: PropTypes,
};
