// Please see this excellent answer https://stackoverflow.com/a/66985940/4087799 for more details
import React from "react";

export default function useImperativeDisableScroll({ element, disabled }) {
  React.useEffect(() => {
    if (!element) {
      return;
    }
    element.style.overflowY = disabled ? "hidden" : "scroll";
    return () => {
      element.style.overflowY = "scroll";
    };
  }, [disabled, element]);
}
