import { Link } from "../components/Link";
import { PageContainer } from "../components/PageContainer";

export const Skills = ({ data }) => {
  return (
    <PageContainer>
      <div className="h-full pt-20 md:pt-12 space-y-20 flex flex-col items-center justify-center overflow-hidden">
        {data["skills"].map((skill) => (
          <Link className="text-4xl md:text-9xl" to={skill} type="big">
            {skill}
          </Link>
        ))}
      </div>
    </PageContainer>
  );
};
